import { ScheduleLabLink } from 'components/links/scheduleLabLink';
import { StandardLayout } from 'layouts/standardLayout/standardLayout';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as pageClasses from 'scss/legalDocuments.module.scss';

const TermsPage: React.FC = () => {
  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>ScheduleLab - Terms of Service</title>
        <meta charSet="utf-8" />
      </Helmet>

      <StandardLayout title="Terms of Service">
        <article className={pageClasses.container}>
          <section>
            <p>
              <em>Last Updated: May 1, 2021</em>
            </p>
            <p>
              <em>Effective Date: May 1, 2021</em>
            </p>
          </section>
          <section>
            <h2>Introduction</h2>
            <p>
              These terms of service outline the rules and regulations for the use of ScheduleLab, LLC’s Website and
              App.
            </p>
            <p>ScheduleLab, LLC is located at: 200 Bedford Rd Apt 24B Woburn MA 01801, United States of America</p>
            <p>
              By accessing this website we assume you accept these terms of service in full. Do not continue to use
              ScheduleLab, LLC’s website if you do not accept all of the terms of service stated on this page. The
              following terminology applies to these Terms of Service, Privacy Statement and Disclaimer Notice and any
              or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and
              accepting the Company’s terms of service. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
              Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or
              ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
              the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of
              a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of
              United States of America. Any use of the above terminology or other words in the singular, plural,
              capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
            </p>
          </section>
          <section>
            <h2>License</h2>
            <p>
              Unless otherwise stated, ScheduleLab, LLC and/or it’s licensors own the intellectual property rights for
              all material on ScheduleLab, LLC. All intellectual property rights are reserved. You may view and/or print
              pages from <ScheduleLabLink /> for your own personal use subject to restrictions set in these terms of
              service. You must not:
            </p>
            <ul>
              <li>
                Republish material from <ScheduleLabLink />
              </li>
              <li>
                Sell, rent or sub-license material from <ScheduleLabLink />
              </li>
              <li>
                Reproduce, duplicate or copy material from <ScheduleLabLink />
              </li>
            </ul>
            <p> Redistribute content from ScheduleLab, LLC (unless content is specifically made for redistribution).</p>
          </section>
          <section>
            <h2>IFrames</h2>
            <p>
              Without prior approval and express written permission, you may not create frames around our Web pages or
              use other techniques that alter in any way the visual presentation or appearance of our Web site.
              Additionally, you may not frame our pages in a deceptive or otherwise malicious manner.
            </p>
          </section>
          <section>
            <h2> Reservation of Rights</h2>
            <p>
              We reserve the right at any time and in its sole discretion to request that you remove all links or any
              particular link to our Web site. You agree to immediately remove all links to our Web site upon such
              request. We also reserve the right to amend these terms of service and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and abide by these linking terms of service.
            </p>
          </section>
          <section>
            <h2>Removal of links from our website</h2>
            <p>
              If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
              us about this. We will consider requests to remove links but will have no obligation to do so or to
              respond directly to you.
            </p>
            <p>
              Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its
              completeness or accuracy; nor do we commit to ensuring that the website remains available or that the
              material on the website is kept up to date.
            </p>
          </section>
          <section>
            <h2> Content Liability</h2>
            <p>
              We shall have no responsibility or liability for any content appearing on your Web site. You agree to
              indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may
              appear on any page on your Web site or within any context containing content or materials that may be
              interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
          </section>
          <section>
            <h2>Disclaimer</h2>
            <p>
              To the maximum extent permitted by applicable law, we exclude all representations, warranties and
              conditions relating to our website and the use of this website (including, without limitation, any
              warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of
              reasonable care and skill). Nothing in this disclaimer will:
            </p>
            <ul>
              <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
              <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
              <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
              <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>
            <p>
              The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
              are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in
              relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
              (including negligence) and for breach of statutory duty. To the extent that the website and the
              information and services on the website are provided free of charge, we will not be liable for any loss or
              damage of any nature.
            </p>
          </section>
        </article>
      </StandardLayout>
    </main>
  );
};

export default TermsPage;
